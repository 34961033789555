import { AuthContextProps } from "react-oidc-context";
import {
  FolderStructureResponseDto,
  IApiDeleteFolderResponse,
  IApiUploadUrlResponse,
  ICreateFolderResponse,
  S3Object,
} from "./../api/restModel";
import { IApiResponse, IAppConfig } from "./restModel";
import { determineAPIUrl } from "./../utils/environmentUtils";
import {
  isCreateNewFolderAllowed,
  isDeleteFileAllowed,
  isDeleteFolderAllowed,
  isDownloadFileAllowed,
  isRenameFolderAllowed,
  isUploadFileAllowed,
} from "./authorizationSettings";
import axios, { AxiosInstance } from "axios";
import qs from "qs";

export const axiosInstance: AxiosInstance = axios.create({
  timeout: 25000,
  maxBodyLength: Infinity,
  maxContentLength: Infinity,
  baseURL: determineAPIUrl(window.location),
});

const registerInterceptor = (auth: AuthContextProps | null) => {
  if (!auth) {
    console.warn("The authentication context is null!");
    return;
  }

  let isRefreshing = false;
  let refreshTokenPromise: Promise<any> | null = null;

  axiosInstance.interceptors.response.use(
    (response) => response,
    async function (error) {
      const originalRequest = error.config;

      if ((error.response.status === 401 || error.response.status === 403) && !originalRequest._retry) {
        console.debug("Performing re-authentication to get token ...");
        originalRequest._retry = true;

        if (!isRefreshing) {
          isRefreshing = true;
          refreshTokenPromise = auth
            .signinSilent()
            .then((user) => {
              if (user) {
                const newToken = "Bearer " + user.access_token;
                axiosInstance.defaults.headers.common.Authorization = newToken; // Update for future requests
                isRefreshing = false;
                return newToken;
              }
            })
            .catch(() => {
              isRefreshing = false;
              return null;
            })
            .finally(() => {
              refreshTokenPromise = null;
            });
        }

        const newToken = await refreshTokenPromise;
        if (newToken) {
          originalRequest.headers.Authorization = newToken; // Update the failed request with new token
          return axiosInstance(originalRequest); // Retry the original request
        }
      }

      return Promise.reject(error);
    }
  );
};

// return config from config lambda
export async function getAppConfig(): Promise<IAppConfig | null> {
  try {
    const response = await axiosInstance.get("/config");
    return response.data;
  } catch (error: any) {
    console.error("Error while trying to query application config.", JSON.stringify(error));
    throw error;
  }
}

// comment in for local developement
// export async function getAppConfig(): Promise<IAppConfig | null> {
//   try {
//     const apiAxiosInstance = axios.create({
//       timeout: 25000,
//       maxBodyLength: Infinity,
//       maxContentLength: Infinity,
//       baseURL: `https://api.s3-web-client.qa-test2.dev-saas.zeb-it.de`,
//       //baseUrl might change in the future
//     });
//     const response = await apiAxiosInstance.get("/config");
//     return response.data;
//   } catch (error: any) {
//     console.error("Error while trying to query application config.", JSON.stringify(error));
//     return null;
//   }
// }

export async function getVirusScanEnabled(auth: AuthContextProps): Promise<boolean> {
  try {
    registerInterceptor(auth);

    const response = await axiosInstance.get<boolean>("api/virusScanEnabled");
    return response.data;
  } catch (error: any) {
    throw new Error("Error while trying to query virus-scan-enabled: " + JSON.stringify(error));
  }
}

export async function getFolders(auth: AuthContextProps | null, currentFolder: string): Promise<FolderStructureResponseDto> {
  registerInterceptor(auth);
  const encodedCurrentFolder = encodeURIComponent(currentFolder);
  const response = await axiosInstance.get<FolderStructureResponseDto>(
    "/api/folders" + (currentFolder.length > 0 ? "?path=" + encodedCurrentFolder : "")
  );

  const mappedContent = response.data.content.map((item) => {
    const status = item.tags ? item.tags["virus-scan-status"] ?? item.tags["virus-scan"] : "required";
    return { ...item, virusScanStatus: status };
  });

  return {
    ...response.data,
    content: mappedContent,
  };
}

export async function multiPartUpload(
  auth: AuthContextProps | null,
  fileToUpload: File,
  path: string,
  currentFolderS3Object: S3Object,
  updateFileWithUploadProgress: any,
  virusScanEnabled: boolean | undefined
): Promise<IApiResponse> {
  const uploadId = await initMultiPartUpload(path, fileToUpload, auth);

  const promisesArray = await uploadEachPart(
    fileToUpload,
    auth,
    path,
    currentFolderS3Object,
    uploadId,
    updateFileWithUploadProgress,
    virusScanEnabled
  );

  const resolvedArray = await Promise.all(promisesArray);

  return await finishMultipartUpload(auth, fileToUpload, path, resolvedArray, uploadId);
}

async function uploadEachPart(
  fileToUpload: File,
  auth: AuthContextProps | null,
  path: string,
  currentFolderS3Object: S3Object,
  uploadId: string,
  updateFileWithUploadProgress: any,
  virusScanEnabled: boolean | undefined
) {
  const promisesArray: any[] = [];
  let start;
  let end;
  let blob;
  const CHUNK_SIZE = 10000000; // 50MB
  const CHUNKS_COUNT = Math.floor(fileToUpload.size / CHUNK_SIZE) + 1;
  for (let index = 1; index < CHUNKS_COUNT + 1; index++) {
    start = (index - 1) * CHUNK_SIZE;
    end = index * CHUNK_SIZE;
    blob = index < CHUNKS_COUNT ? fileToUpload.slice(start, end) : fileToUpload.slice(start);

    const uploadUrl = await getUploadPartUrl(
      auth,
      fileToUpload,
      path,
      currentFolderS3Object,
      index,
      uploadId,
      virusScanEnabled
    );
    promisesArray.push(await uploadPart(auth, blob, currentFolderS3Object, uploadUrl));
    updateFileWithUploadProgress(fileToUpload, index * CHUNK_SIZE);
  }
  return promisesArray;
}

async function initMultiPartUpload(path: string, fileToUpload: File, auth: AuthContextProps | null) {
  const formData = new FormData();
  if (path !== "") {
    formData.append("path", path);
  }
  formData.append("file-name", fileToUpload.name);
  formData.append("content-type", fileToUpload.type);

  registerInterceptor(auth);
  const initMultiPartUploadUrl = await axiosInstance.post("/api/multipartUpload/start", formData, {
    headers: {
      "Content-Type": fileToUpload.type,
    },
  });
  return initMultiPartUploadUrl.data.url;
}

export async function uploadPart(
  auth: AuthContextProps | null,
  blob: Blob,
  currentFolderS3Object: S3Object,
  uploadUrl: IApiUploadUrlResponse
): Promise<any> {
  if (isUploadFileAllowed(currentFolderS3Object)) {
    registerInterceptor(auth);

    const responseUpload = await axios.put(uploadUrl.url, blob, {
      headers: {
        "Content-Type": blob.type,
      },
    });
    return responseUpload;
  } else {
    throw {
      response: {
        status: 403,
      },
    };
  }
}

export async function getUploadPartUrl(
  auth: AuthContextProps | null,
  file: File,
  path: string,
  currentFolderS3Object: S3Object,
  partNumber: number,
  uploadId: any,
  virusScanEnabled: boolean | undefined
): Promise<IApiUploadUrlResponse> {
  if (isUploadFileAllowed(currentFolderS3Object)) {
    const formData = new FormData();
    if (path !== "") {
      formData.append("path", path);
    }
    formData.append("file-name", file.name);
    formData.append("content-type", file.type);
    formData.append("part-number", partNumber.toString());
    formData.append("uploadId", uploadId.toString());

    registerInterceptor(auth);

    const responseUrl = await axiosInstance.post("/api/multipartUpload/url", formData, {
      headers: {
        "Content-Type": file.type,
        ...(virusScanEnabled && { "x-amz-tagging": "origin=s3-web-client&virus-scan=required" }),
      },
    });

    return responseUrl.data;
  } else {
    throw {
      response: {
        status: 403,
      },
    };
  }
}

export async function uploadFile(
  auth: AuthContextProps | null,
  fileToUpload: File,
  path: string,
  currentFolderS3Object: S3Object,
  virusScanEnabled: boolean | undefined
): Promise<IApiResponse> {
  if (isUploadFileAllowed(currentFolderS3Object)) {
    registerInterceptor(auth);

    const uploadUrl = await getUploadFileUrl(auth, fileToUpload, path, currentFolderS3Object);

    const responseUpload = await axios.put(uploadUrl.url, fileToUpload, {
      headers: {
        "Content-Type": fileToUpload.type,
        ...(virusScanEnabled && { "x-amz-tagging": "origin=s3-web-client&virus-scan=required" }),
      },
    });
    return responseUpload.data;
  } else {
    throw {
      response: {
        status: 403,
      },
    };
  }
}

export async function getUploadFileUrl(
  auth: AuthContextProps | null,
  fileToUpload: File,
  path: string,
  currentFolderS3Object: S3Object
): Promise<IApiUploadUrlResponse> {
  if (isUploadFileAllowed(currentFolderS3Object)) {
    const formData = new FormData();
    if (path !== "") {
      formData.append("path", path);
    }
    formData.append("file-name", fileToUpload.name);
    formData.append("content-type", fileToUpload.type);

    registerInterceptor(auth);
    const responseUrl = await axiosInstance.post("/api/files", formData, {
      headers: {
        "Content-Type": fileToUpload.type,
      },
    });

    return responseUrl.data;
  } else {
    throw {
      response: {
        status: 403,
      },
    };
  }
}

export async function getDownloadUrl(
  auth: AuthContextProps | null,
  fileToDownload: S3Object,
  currentFolderS3Object: S3Object,
  virusScanEnabled: boolean | undefined
): Promise<string> {
  if (isDownloadFileAllowed(currentFolderS3Object, virusScanEnabled)) {
    registerInterceptor(auth);
    const encodedPath = encodeURIComponent(fileToDownload.s3Key);
    const responseUrl = await axiosInstance.get("/api/files?path=" + encodedPath);
    return responseUrl.data.url;
  } else {
    throw {
      response: {
        status: 403,
      },
    };
  }
}

export async function getMultipleDownloadUrls(
  auth: AuthContextProps | null,
  filesToDownload: Set<S3Object>,
  virusScanEnabled: boolean | undefined
): Promise<any> {
  registerInterceptor(auth);
  for (const s3Object of filesToDownload) {
    if (!isDownloadFileAllowed(s3Object, virusScanEnabled)) {
      throw {
        response: {
          status: 403,
        },
      };
    }
  }
  const encodedPaths = Array.from(filesToDownload).map((file) => file.s3Key);
  const multipleResponseUrls = await axiosInstance.get("/api/multipleFiles?path=", {
    params: {
      s3Keys: encodedPaths,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
  return multipleResponseUrls.data.urls;
}

export async function deleteFile(auth: AuthContextProps | null, fileToDelete: S3Object): Promise<IApiResponse> {
  if (isDeleteFileAllowed(fileToDelete)) {
    registerInterceptor(auth);
    const encodedPath = encodeURIComponent(fileToDelete.s3Key);
    const response = await axiosInstance.delete("/api/files?path=" + encodedPath);
    return response.data;
  } else {
    throw {
      response: {
        status: 403,
      },
    };
  }
}

export async function createFolder(
  auth: AuthContextProps | null,
  folderName: string,
  currentFolder: string,
  currentFolderS3Object: S3Object
): Promise<ICreateFolderResponse> {
  if (isCreateNewFolderAllowed(currentFolderS3Object)) {
    registerInterceptor(auth);
    const options = {
      folderName: folderName,
      path: currentFolder === "" ? null : currentFolder,
    };
    const response = await axiosInstance.post("/api/folders", options);
    return response.data;
  } else {
    throw {
      response: {
        status: 403,
      },
    };
  }
}

export async function renameFolder(auth: AuthContextProps | null, currentFolderS3Object: S3Object): Promise<IApiResponse> {
  if (isRenameFolderAllowed(currentFolderS3Object)) {
    registerInterceptor(auth);
    const response = await axiosInstance.post("/api/folders/{path}/files/{fileName}"); // encode path
    return response.data;
  } else {
    throw {
      response: {
        status: 403,
      },
    };
  }
}

export async function deleteFolder(
  auth: AuthContextProps | null,
  folderToDelete: string,
  currentFolderS3Object: S3Object
): Promise<IApiDeleteFolderResponse> {
  if (isDeleteFolderAllowed(currentFolderS3Object)) {
    registerInterceptor(auth);
    const encodedfolderToDelete = encodeURIComponent(folderToDelete);
    const response = await axiosInstance.delete("/api/folders?path=" + encodedfolderToDelete);
    return response.data;
  } else {
    throw {
      response: {
        status: 403,
      },
    };
  }
}

async function finishMultipartUpload(
  auth: AuthContextProps | null,
  fileToUpload: File,
  path: string,
  eTagArray: any[],
  uploadId: any
): Promise<IApiUploadUrlResponse> {
  const formData = new FormData();
  if (path !== "") {
    formData.append("path", path);
  }
  formData.append("file-name", fileToUpload.name);
  formData.append("uploadId", uploadId);
  eTagArray.forEach((response) => {
    formData.append("uploaded-parts", response.headers.etag);
  });
  registerInterceptor(auth);
  const responseUrl = await axiosInstance.post("/api/multipartUpload/finish", formData, {
    headers: {
      "Content-Type": fileToUpload.type,
    },
  });

  return responseUrl.data;
}

export async function moveFileRequest(
  auth: AuthContextProps | null,
  fileToMove: S3Object,
  toFolder: string
): Promise<IApiResponse> {
  const toS3Key = toFolder === "/" ? fileToMove.name : `${toFolder}${fileToMove.name}`;
  // TODO: extra recht für move file!?
  if (isDeleteFileAllowed(fileToMove)) {
    registerInterceptor(auth);

    const payload = {
      fromS3Key: fileToMove.s3Key,
      toS3Key: toS3Key,
    };

    const response = await axiosInstance.put("/api/files/move", payload);
    return response.data;
  } else {
    throw {
      response: {
        status: 403,
      },
    };
  }
}

import { Grid, Paper } from "@mui/material";
import { S3Object } from "../api/restModel";
import { createTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { zebStyles as parentZebStyles } from "../zebstyles";
import { useAuth } from "react-oidc-context";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import BucketView from "./files/BucketView";
import FolderTreeView from "./folders/FolderTreeView";
import Header from "./header/Header";
import useAPIError from "../dialogs/error/useAPIError";
import useLoadingFiles from "../contexts/useLoadingFiles";

const theme = createTheme();
const useStyles = makeStyles(() => ({
  ...parentZebStyles(theme),
  panel: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignContent: "start",
    alignItems: "flex-start",
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    height: "100%",
    width: "100%",
  },
}));

export default function MainView() {
  const classes = useStyles();

  const [currentFolder, setCurrentFolder] = useState<string>("/");

  const [folderMap, setFolderMap] = useState<Map<string, S3Object[]>>(new Map());
  const auth = useAuth();
  const { loadingFiles } = useLoadingFiles();
  const { addError } = useAPIError();
  const { formatMessage } = useIntl();

  const updateFolderMap = (key: string, value: S3Object[]) => {
    setFolderMap((map) => new Map(map.set(key, value)));
  };

  useEffect(() => {
    const confirmExit = () => {
      if (loadingFiles.length > 0) {
        addError(formatMessage({ id: "warning.fileUploading.message" }), "warning");
        return "warning dialog";
      }
    };
    window.onbeforeunload = confirmExit;
  }, [addError, formatMessage, loadingFiles.length]);

  return (
    <div className={classes.panel}>
      <Header />
      <Grid container spacing={0} sx={{ height: "calc(100% - 5rem)" }}>
        {auth.user && (
          <>
            <Grid item xs={4} sx={{ height: "100%" }}>
              <Paper sx={{ height: "100%", margin: "0.5rem", padding: "0.5rem" }} elevation={4}>
                <FolderTreeView
                  currentFolder={currentFolder}
                  setCurrentFolder={setCurrentFolder}
                  folderMap={folderMap}
                  updateFolderMap={updateFolderMap}
                  setFolderMap={setFolderMap}
                />
              </Paper>
            </Grid>
            <Grid item xs={8} sx={{ height: "100%" }}>
              <BucketView
                currentFolder={currentFolder}
                setCurrentFolder={setCurrentFolder}
                folderMap={folderMap}
                updateFolderMap={updateFolderMap}
                setFolderMap={setFolderMap}
              />
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
}

import { Box, Button } from "@mui/material";
import { DriveFileMoveOutlined } from "@mui/icons-material";
import { S3Object } from "../../../api/restModel";
import { formatErrorToMessageId } from "../../../api/errorUtil";
import { isMoveFileAllowed } from "../../../api/authorizationSettings";
import { moveFileRequest } from "../../../api/restFacade";
import { useAuth } from "react-oidc-context";
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useVirusScanEnabledApi } from "../../../api/useVirusScanEnabledApi";
import MoveFileDialog from "../../../dialogs/MoveFileDialog";
import useAPIError from "../../../dialogs/error/useAPIError";
import useLoadingFiles from "../../../contexts/useLoadingFiles";

interface MoveFileButtonProps {
  openDialog: boolean;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  selectedRows: Set<S3Object> | undefined;
  currentFolder: string;
  moveFile(oldKey: string, file: S3Object, newKey: string): void;
  folderMap: Map<string, S3Object[]>;
  setFolderMap(map: Map<string, S3Object[]>): void;
  updateFolderMap(key: string, value: S3Object[]): void;
}

const MoveFileButton = ({
  selectedRows,
  currentFolder,
  moveFile,
  folderMap,
  setFolderMap,
  updateFolderMap,
  openDialog,
  setOpenDialog,
}: MoveFileButtonProps): JSX.Element => {
  const auth = useAuth();
  const { formatMessage } = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const { addError } = useAPIError();

  const { addMovingFile, removeMovingFile } = useLoadingFiles();

  const { data: virusScanEnabled } = useVirusScanEnabledApi();

  const [filesToMove, setFilesToMove] = useState<Set<S3Object>>();

  const [selectedFolder, setSelectedFolder] = useState<string>("/");

  const closeDialog = (): void => {
    setSelectedFolder("/"); //set selected folder to default state
    setOpenDialog(false);
  };

  const checkFilesForMove = (): void => {
    if (!selectedRows) return;
    setFilesToMove(selectedRows);
    setOpenDialog(true);
  };

  const canFilesBeMoved = (): boolean => {
    if (selectedRows) {
      for (const file of selectedRows) {
        if (!isMoveFileAllowed(file, virusScanEnabled)) {
          return true;
        }
      }
    }
    return selectedRows?.size === 0;
  };

  const moveFileCall = () => {
    if (!filesToMove) return;

    const errorMessages = new Array<string>();

    filesToMove.forEach((fileToMove: S3Object) => {
      addMovingFile(fileToMove);
      moveFileRequest(auth, fileToMove, selectedFolder)
        .then(() => {
          removeMovingFile(fileToMove);
          moveFile(currentFolder, fileToMove, selectedFolder);
          enqueueSnackbar(formatMessage({ id: "snackbar.moveFileSuccess" }, { fileName: fileToMove.name }), {
            variant: "success",
          });
        })
        .catch((err) => {
          removeMovingFile(fileToMove);
          errorMessages.push(
            `${fileToMove.name}: ${formatMessage(
              { id: formatErrorToMessageId(err) },
              { error: JSON.stringify(err), fileName: fileToMove.name }
            )}`
          );
          addError(errorMessages, "error");
        });
    });

    closeDialog();
  };

  return (
    <>
      <Box>
        <Button
          sx={{ mr: "0.5rem", ml: "0.5rem" }}
          variant="outlined"
          startIcon={<DriveFileMoveOutlined />}
          component="span"
          disabled={canFilesBeMoved()}
          onClick={checkFilesForMove}>
          {formatMessage({ id: "button.moveFile" })}
        </Button>
      </Box>
      <MoveFileDialog
        open={openDialog}
        onClose={closeDialog}
        onConfirm={moveFileCall}
        selectedFolder={selectedFolder}
        setSelectedFolder={setSelectedFolder}
        folderMap={folderMap}
        setFolderMap={setFolderMap}
        updateFolderMap={updateFolderMap}
        filesToMove={filesToMove}
      />
    </>
  );
};

export default MoveFileButton;

import { Permission, S3Object } from "./restModel";

export function isDescribeFolderAllowed(currentObject: S3Object): boolean {
  return isNeededRightInAuth(currentObject, Permission.DESCRIBE_FOLDER);
}

export function isCreateNewFolderAllowed(currentObject: S3Object): boolean {
  return isNeededRightInAuth(currentObject, Permission.CREATE_FOLDER);
}

export function isDeleteFolderAllowed(currentObject: S3Object): boolean {
  return isNeededRightInAuth(currentObject, Permission.DELETE_FOLDER);
}

export function isRenameFolderAllowed(currentObject: S3Object): boolean {
  return isNeededRightInAuth(currentObject, Permission.RENAME_FOLDER);
}

export function isDescribeFileAllowed(currentObject: S3Object): boolean {
  return isNeededRightInAuth(currentObject, Permission.DESCRIBE_FILE);
}

export function isDownloadFileAllowed(currentObject: S3Object, virusScanEnabled: boolean | undefined): boolean {
  // if virus scan feature is not enabled, just check permission to download file
  if (!virusScanEnabled) {
    return isNeededRightInAuth(currentObject, Permission.DOWNLOAD_FILE);
  }

  // else check virus-scan tags aswell
  if (!currentObject.tags["virus-scan"] || !currentObject.tags["virus-scan-status"]) {
    return false;
  }

  return (
    isNeededRightInAuth(currentObject, Permission.DOWNLOAD_FILE) &&
    currentObject.tags["virus-scan-status"].toLowerCase() === "clean"
  );
}

export function isUploadFileAllowed(currentObject: S3Object): boolean {
  return isNeededRightInAuth(currentObject, Permission.UPLOAD_FILE);
}

export function isDeleteFileAllowed(currentObject: S3Object): boolean {
  return isNeededRightInAuth(currentObject, Permission.DELETE_FILE);
}

export function isMoveFileAllowed(currentObject: S3Object, virusScanEnabled: boolean | undefined): boolean {
  // if virus scan feature is not enabled, just check permission to move file
  if (!virusScanEnabled) {
    return isNeededRightInAuth(currentObject, Permission.MOVE_FILE);
  }

  // else check virus-scan tags aswell
  if (!currentObject.tags["virus-scan"] || !currentObject.tags["virus-scan-status"]) {
    return false;
  }

  return (
    isNeededRightInAuth(currentObject, Permission.MOVE_FILE) &&
    currentObject.tags["virus-scan-status"].toLowerCase() === "clean"
  );
}

function isNeededRightInAuth(currentObject: S3Object, neededRight: Permission): boolean {
  if (currentObject && neededRight) {
    return currentObject.permissions.includes(neededRight);
  }
  return false;
}

import { getVirusScanEnabled } from "./restFacade";
import { useAuth } from "react-oidc-context";
import { useQuery } from "@tanstack/react-query";

export const useVirusScanEnabledApi = () => {
  const auth = useAuth();

  return useQuery({
    queryKey: ["virusScanEnabled"],
    queryFn: async () => {
      const response = await getVirusScanEnabled(auth);
      return response;
    },
    refetchOnWindowFocus: false,
  });
};
